/* montserrat-regular - latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 400;
	src: url('../font/montserrat/montserrat-v15-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
			 url('../font/montserrat/montserrat-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			 url('../font/montserrat/montserrat-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
			 url('../font/montserrat/montserrat-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
			 url('../font/montserrat/montserrat-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
			 url('../font/montserrat/montserrat-v15-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 500;
	src: url('../font/montserrat/montserrat-v15-latin-500.eot'); /* IE9 Compat Modes */
	src: local(''),
			 url('../font/montserrat/montserrat-v15-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			 url('../font/montserrat/montserrat-v15-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
			 url('../font/montserrat/montserrat-v15-latin-500.woff') format('woff'), /* Modern Browsers */
			 url('../font/montserrat/montserrat-v15-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
			 url('../font/montserrat/montserrat-v15-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 700;
	src: url('../font/montserrat/montserrat-v15-latin-700.eot'); /* IE9 Compat Modes */
	src: local(''),
			 url('../font/montserrat/montserrat-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			 url('../font/montserrat/montserrat-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
			 url('../font/montserrat/montserrat-v15-latin-700.woff') format('woff'), /* Modern Browsers */
			 url('../font/montserrat/montserrat-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
			 url('../font/montserrat/montserrat-v15-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-800 - latin */
@font-face {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 800;
	src: url('../font/montserrat/montserrat-v15-latin-800.eot'); /* IE9 Compat Modes */
	src: local(''),
			 url('../font/montserrat/montserrat-v15-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			 url('../font/montserrat/montserrat-v15-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
			 url('../font/montserrat/montserrat-v15-latin-800.woff') format('woff'), /* Modern Browsers */
			 url('../font/montserrat/montserrat-v15-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
			 url('../font/montserrat/montserrat-v15-latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
}
