@import './variables';
@import './font';

body, html {
	min-width: 375px;
	user-select: none;
}

:focus {
	outline: none;
}

body, html, button {
	font-family: $font-family;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

*, *:before, *:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

body, p, h1, h2 {
	margin: 0;
}

input {
	margin: 0;
	border: 0;
	padding: 0;
	outline: none;
	background: transparent;
	appearance: none;
}

button {
	margin: 0;
	border: 0;
	padding: 0;
	background: transparent;
	touch-action: none;
}

@keyframes loader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.spin {
	min-width: 375px;
	opacity: 0;
	pointer-events: none;
	position: fixed;
	z-index: 3000;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(#fff, .9);
	display: flex;
	align-items: center;
	justify-content: center;
	i {
		display: block;
		width: 52px;
		height: 52px;
		border: 4px solid rgba($c-green, .2);
		border-top-color: $c-green;
		border-radius: 50%;
		pointer-events: none;
		animation: loader 1.2s ease infinite;
		opacity: 0;
		transition: opacity .2s ease;
	}
}

.logo {
	width: 300px;
	margin: 0 auto;
	svg {
		path {
			fill: #414042;
			&.check {
				fill: $c-green;
			}
		}
	}
}

.home-screen {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100vh;
	padding: 60px 32px;
	background: #fff repeat url(../img/bg-tile.png);
	background-size: 80%;
	.cam-preview-,
	.cam-preview {
		min-width: 375px;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.cam-preview {
		padding: 70px 32px;
		z-index: 1000;
		opacity: 0;
		pointer-events: none;
		background: #000;
		border-radius: 24px;
		transform: scale(.6) translateY(120px);
		transition: transform .25s ease-out, opacity .25s ease-out, border-radius .25s ease-out;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
		.info,
		.scan {
			opacity: 0;
			transform: scale(.9) translateY(10px);
			transition: transform .2s ease-out .22s, opacity .2s ease-out .22s;
		}
		.info {
			z-index: 1002;
			position: relative;
		}
	}
	&.authenticating {
		.spin {
			opacity: 1;
			pointer-events: auto;
		}
	}
	&.authenticating-loading {
		.spin {
			i {
				opacity: 1;
			}
		}
	}
	&.openned {
		.cam-preview {
			opacity: 1;
			pointer-events: auto;
			border-radius: 0;
			transform: scale(1) translateY(0);
			.info,
			.scan {
				opacity: 1;
				transform: scale(1) translateY(0);
			}
		}
	}
	&.openning {
		.scan-trigger {
			cursor: default;
			pointer-events: none;
			border-color: transparent;
			transform: scale(.95);
			&:after {
				opacity: 1;
				animation: loader 1.2s ease infinite;
			}
		}
	}
	&.taking {
		.photo-trigger {
			cursor: default;
			pointer-events: none;
			border-color: transparent;
			transform: scale(.95);
			&:after {
				opacity: 1;
				animation: loader 1.2s ease infinite;
			}
		}
	}
}

.scan-trigger-wrap {
	text-align: center;
	margin: 20px 0;
}
.scan-trigger {
	width: 142px;
	height: 142px;
	border: 12px solid $c-green;
	border-radius: 50%;
	color: $tc-green;
	font-size: 22px;
	font-weight: 800;
	text-transform: uppercase;
	cursor: pointer;
	transition: transform .2s ease, border-color .2s ease;
	-webkit-backface-visibility: hidden;
	-webkit-transform: perspective(142px);
	&:active {
		color: $tc-green;
		transform: scale(.95);
	}
	&:after {
		content: '';
		opacity: 0;
		border: 12px solid rgba($c-green, .2);
		border-top-color: $c-green;
		position: absolute;
		top: -12px;
		left: -12px;
		right: -12px;
		bottom: -12px;
		border-radius: 50%;
		transition: opacity .2s ease;
	}
}

.info {
	display: flex;
	flex-shrink: 0;
	padding: 18px 16px;
	border-radius: 24px;
	background: #fff;
	border: 1px solid #dad7d7;
	&.noBorder {
		border: 0;
	}
	svg {
		fill: $c-black;
		width: 28px;
		min-width: 28px;
		height: 28px;
	}
	p {
		margin: 0 0 0 10px;
		color: $tc-black2;
		font-size: 15px;
	}
}

.scan {
	width: 350px;
	height: 350px;
	min-height: 80px;
	margin: 20px 0;
	position: relative;
	z-index: 1002;
	svg {
		width: 30px;
		height: 30px;
		position: absolute;
		&:nth-child(1) {
			top: 0;
			left: 0;
		}
		&:nth-child(2) {
			top: 0;
			right: 0;
			transform: rotate(90deg);
		}
		&:nth-child(3) {
			bottom: 0;
			right: 0;
			transform: rotate(180deg);
		}
		&:nth-child(4) {
			bottom: 0;
			left: 0;
			transform: rotate(-90deg);
		}
	}
}

.photo-trigger {
	font-size: 0;
	width: 130px;
	height: 130px;
	min-width: 130px;
	min-height: 130px;
	border: 12px solid #fff;
	border-radius: 50%;
	position: relative;
	z-index: 1002;
	text-align: center;
	cursor: pointer;
	transition: transform .2s ease, border-color .2s ease;
	-webkit-backface-visibility: hidden;
	-webkit-transform: perspective(130px);
	&:active {
		color: $tc-green;
		transform: scale(.95);
	}
	&:before {
		content: '';
		display: inline-block;
		background: $c-green;
		width: 90px;
		height: 90px;
		border-radius: 50%;
	}
	&:after {
		opacity: 0;
		content: '';
		border: 12px solid rgba(#fff, .2);
		border-top-color: #fff;
		position: absolute;
		top: -12px;
		left: -12px;
		right: -12px;
		bottom: -12px;
		border-radius: 50%;
		transition: transform .2s ease, border-color .2s ease;
		transform: scale(.95);
	}
	&.disabled {
		cursor: default;
		pointer-events: none;
	}
}

.res-preview {
	padding: 106px 32px;
	position: fixed;
	z-index: 1002;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff repeat;
	background-size: 80%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	.prev-data {
		height: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}
	svg {
		width: 172px;
		height: 172px;
	}
	h1 {
		font-size: 22px;
		font-weight: bold;
		text-transform: uppercase;
		margin: 28px 0 62px;
	}
	p {
		color: $tc-black;
		font-size: 16px;
		line-height: 22px;
		font-weight: 500;
		text-align: center;
	}
	&.approved {
		background-image: url(../img/bg-tile-green.png);
		h1 {
			color: $c-green;
		}
	}
	&.expired {
		background-image: url(../img/bg-tile-red.png);
		h1 {
			color: $c-red;
		}
	}
	&.expiring {
		background-image: url(../img/bg-tile-yellow.png);
		svg {
			width: 192px;
			height: 170px;
		}
		h1 {
			color: $c-yellow;
		}
	}
	.add-scan {
		cursor: pointer;
		border: 4px solid $c-black;
		border-radius: 26px;
		padding: 0 28px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		span {
			line-height: 47px;
			color: $tc-black;
			font-size: 16px;
			font-weight: bold;
			white-space: nowrap;
		}
		svg {
			margin: 0 0 0 18px;
			width: 14px;
			height: 14px;
			min-width: 14px;
			min-height: 14px;
		}
	}
}

.not-found {
	color: #fff;
	background: $c-red;
	position: absolute;
	top: 50%;
	left: 32px;
	right: 32px;
	z-index: 1003;
	border-radius: 16px;
	padding: 30px;
	text-align: center;
	opacity: 0;
	pointer-events: none;
	transform: translateY(-79%) scale(.99);
	transition: opacity .15s ease-out, transform .15s ease-out;
	&.shown {
		opacity: 1;
		pointer-events: auto;
		transform: translateY(-80%) scale(1);
	}
	h2 {
		font-size: 18px;
		font-weight: 700;
		text-transform: uppercase;
		margin: 0 0 20px;
	}
	& > button {
		color: #fff;
		font-size: 14px;
		font-weight: 500;
		padding: 5px 10.5px;
		border-radius: 14px;
		border: 1px solid #fff;
		cursor: pointer;
		transition: transform .2s ease;
		&:active {
			transform: scale(.98);
		}
	}
	p {
		font-size: 14px;
		font-weight: 500;
		margin: 16px 0 7px;
	}
	.code-form {
		input {
			color: $c-black;
			border: 0;
			background: #fff;
			padding: 13px 17px;
		}
		button {
			background: #900710;
		}
	}
}

.message {
	min-width: 310px;
	color: $c-black;
	border: 1px solid #dad7d7;
	background: #fff;
	position: absolute;
	top: 50%;
	left: 32px;
	right: 32px;
	z-index: 4003;
	border-radius: 16px;
	padding: 30px;
	text-align: center;
	opacity: 0;
	pointer-events: none;
	transform: translateY(-79%) scale(.99);
	transition: opacity .15s ease-out, transform .15s ease-out;
	&.shown {
		opacity: 1;
		pointer-events: auto;
		transform: translateY(-80%) scale(1);
	}
	h2 {
		font-size: 18px;
		font-weight: 700;
		text-transform: uppercase;
	}
	button {
		color: $tc-grey;
		border: 1px solid $tc-grey;
		font-size: 14px;
		font-weight: 500;
		padding: 5px 10.5px;
		border-radius: 14px;
		margin: 16px 0 0;
		cursor: pointer;
		transition: transform .2s ease;
		&:active {
			transform: scale(.98);
		}
	}
	p {
		font-size: 14px;
		font-weight: 500;
		margin: 20px 0 0;
		&.success {
			color: $c-green;
		}
		&.failure {
			color: $c-red;
		}
	}
	&.is-error {
		color: #fff;
		border-color: $c-red;
		background: $c-red;
		button {
			color: #fff;
			border: 1px solid #fff;
		}
	}
}

.no-cam {
	text-align: center;
	margin: 0 0 22px;
	display: flex;
	justify-content: center;
	p {
		color: $tc-grey;
		margin: 0 0 10px;
		font-family: Arial;
		font-size: 16px;
		font-weight: 400;
	}
	button {
		color: $tc-grey;
		border: 1px solid $tc-grey;
		padding: 5px 10.5px;
		font-size: 14px;
		font-weight: 500;
		border-radius: 14px;
		cursor: pointer;
		transition: transform .2s ease;
		-webkit-backface-visibility: hidden;
		-webkit-transform: perspective(171px);
		&:active {
			transform: scale(.98);
		}
		&:first-child {
			border-right: 0;
			border-radius: 14px 0 0 14px;
		}
		&:last-child {
			border-radius: 0 14px 14px 0;
		}
	}
}

.code-form,
.link-form {
	text-align: center;
	h2 {
		color: $tc-green;
		font-size: 22px;
		font-weight: 800;
		text-transform: uppercase;
		margin: 0 0 14px;
	}
	input {
		width: 100%;
		background: #fff;
		border: 1px solid #e0e0e0;
		border-right: 0;
		padding: 17px 20px;
		font-size: 14px;
		font-weight: 400;
		border-radius: 6px 0 0 6px;
	}
	button {
		cursor: pointer;
		background: $c-green;
		width: 60px;
		min-width: 60px;
		border-radius: 0 6px 6px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		border: none;
		margin: 0;
		svg {
			fill: #fff;
			width: 22px;
			opacity: 1;
			pointer-events: none;
			transform: scale(1) perspective(50px);
			transition: opacity .2s ease, transform .2s ease;
			-webkit-backface-visibility: hidden;
		}
		&:before {
			content: '';
			position: absolute;
			top: calc(50% - 12px);
			left: calc(50% - 12px);
			width: 24px;
			height: 24px;
			border-radius: 50%;
			border: 3px solid rgba(#fff, .2);
			border-top-color: #fff;
			opacity: 0;
			pointer-events: none;
			transform: scale(.2) perspective(50px);
			transition: opacity .2s ease, transform .2s ease;
			-webkit-backface-visibility: hidden;
		}
		&.loading {
			svg {
				opacity: 0;
				transform: scale(.2) perspective(50px);
			}
			&:before {
				opacity: 1;
				transform: scale(1) perspective(50px);
				animation: loader 1.2s ease infinite;
			}
		}
	}
	form {
		display: flex;
	}
}

.link-form {
	margin: 18px 0 0;
}

.stop-scan-trigger {
	width: 32px;
	height: 32px;
	position: absolute;
	z-index: 1002;
	top: 22px;
	right: 32px;
	cursor: pointer;
	svg {
		fill: #fff;
		width: 100%;
		height: 100%;
	}
}

.share-text {
	line-height: 1.8;
	svg {
		height: 24px;
		margin: 0 0 0 6px;
		fill: $c-black;
		display: inline-block;
		vertical-align: text-bottom;
	}
}

@media (prefers-color-scheme: dark) {

	$saturation: 2%;

	.spin {
		background: hsla(210, $saturation, 5%, .95);
		backdrop-filter: blur(8px);
	}

	.logo {
		svg {
			path {
				fill: #fff;
				&.check {
					fill: $c-green;
				}
			}
		}
	}

	body,
	html,
	.home-screen {
		background: hsl(210, $saturation, 5%);
	}

	.info {
		background: hsla(210, $saturation, 12%, .9);
		border-color: hsl(210, $saturation, 14%);
		backdrop-filter: blur(10px);
		svg {
			fill: rgba(#fff, .98);
		}
		p {
			color: rgba(#fff, .98);
		}
	}

	.code-form,
	.link-form {
		input {
			color: #fff;
			background: hsl(210, $saturation, 12%);
			border-color: hsl(210, $saturation, 14%);
		}
	}

	.message {
		color: #fff;
		background: hsla(210, $saturation, 10%, .95);
		backdrop-filter: blur(8px);
		border: 1px solid hsl(210, $saturation, 4%);
		box-shadow: inset 0 1px hsl(210, $saturation, 16%);
		&.is-error {
			box-shadow: none;
			border-color: $c-red;
		}
	}

	.share-text {
		svg {
			fill: $c-green;
		}
	}

}
