$font-family: Montserrat, Arial, Helvetica, sans-serif;

$c-green: #3dae49;
$c-black: #414042;
$c-red: #e81f2d;
$c-yellow: #eea407;

$tc-green: #3dae49;
$tc-black: #414042;
$tc-black2: #1a1a1a;
$tc-grey: #939393;
$tc-red: #e81f2d;
$tc-yellow: #eea407;
